import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import {initializeIcons} from "@fluentui/react/lib/Icons";
import {rcTheme} from "./config/theme";
import {IRcTheme, RcAppAuthTypes, RcReactApp} from "@rocketcodeit/react-components";
import {sidebar} from "./config/sidebar";
import {Slide, ToastContainer, ToastTransition} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Stack } from '@fluentui/react';
import packageJson from "../package.json";

initializeIcons();

//const customerService: CustomerService = new CustomerService();
//const productService: ProductService = new ProductService();

/*
const customSearchCustomer = async (q: string) : Promise<IRcSearchBarCustomSearchResult> => {
    const a = await customerService.find(10,0, `obj.CompanyName.ToLower().Contains("${q}".ToLower())`);

    let counter = 0;
    const mapped = Object.values(a.results).map((val: any) => {
        counter++;
        const a: IRcSearchBarCustomSearchResultItem = {
            icon: <Icon iconName={'Group'} />,
            link: '/customers/' + val.id,
            description: val.companyName!
        }
        return a;
    });

    return {
        title: 'Clienti',
        items: mapped
    };
}*/

const getTheme = () => {
    const aa = {...rcTheme};
    //if(aa.rc && aa.rc.logo) {
    //    ((aa as any).rc?.logo).path = '/assets/fluxo-logo-white.png';
    //}

    return aa;
}



ReactDOM.render(
    <div>
        <RcReactApp theme={getTheme()} menu={sidebar} authType={RcAppAuthTypes.rocket}  />
        <div style={{position: 'absolute', bottom: '0px', right: '0px', padding: '8px', background: 'rgb(244, 244, 244)', width: '100%', boxShadow: '0px 0px 13px -6px #888'}}>
            <Stack horizontal tokens={{childrenGap: 8}} horizontalAlign={'end'} >
                <Stack.Item style={{fontSize: 12}}>
                    ROCKET CODE -
                </Stack.Item>
                <Stack.Item style={{fontSize: 12}}>
                    V. {packageJson.version}
                </Stack.Item>
            </Stack>
        </div>
        <ToastContainer
            toastStyle={{borderRadius: 0}}
            bodyStyle={{borderRadius: 0, fontSize: 14}}
            position={"bottom-right"}
            autoClose={5000}
            hideProgressBar={false}
            closeOnClick={true}
            pauseOnHover={true}
            draggable={true}
            transition={Slide}
        />
    </div>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
