import React from "react";
import {RcApplicationMenu} from "@rocketcodeit/react-components";

const enabledForRole = (user: any, role: string[]) => {

    console.log(role);
    console.log(user?.role?.some((r:string) => role.includes(r)))

    return user?.role?.some((r:string) => role.includes(r));
}

const siderbarMain: RcApplicationMenu[] = [
    {
        id: "dashboard",
        title: "Dashboard",
        icon: "Home",
        routerLink: "/",
        hasIndex: true,
        visible: true,
        enableRoute: true,
        isPublic: false,
        component: React.lazy(() => import("../pages/main/dashboard/DashboardPage")),
    },
    {
        id: "registry",
        title: "Anagrafiche",
        routerLink: "/registries",
        hasIndex: false,
        visible: true,
        enableRoute: false,
        isPublic: false,
        component: React.lazy(() => import("../pages/main/dashboard/DashboardPage")),
        children: [
            {
                id: "cmd-registry-customers",
                title: "Clienti",
                icon: "People",
                routerLink: "/customers",
                hasIndex: true,
                visible: true,
                enableRoute: true,
                isPublic: false,
                component: React.lazy(() => import("../pages/main/customer/ListPage")),
                children:[{
                    id: "cmd-registry-customers-detail",
                    title: "Detail",
                    routerLink: "/:id",
                    visible: false,
                    component: React.lazy(() => import("../pages/main/customer/DetailPage")),
                    isPublic: false,
                }]
            },
            /*{
                id: "cmd-service",
                title: "Prodotti",
                icon: "Product",
                routerLink: "/products",
                component: React.lazy(() => import("../pages/main/product/ListPage")),
                hasIndex: true,
                visible: true,
                enableRoute: true,
                isPublic: false,
                children:[{
                    id: "cmd-registry-service-detail",
                    title: "Detail",
                    routerLink: "/:id",
                    component: React.lazy(() => import("../pages/main/product/SingleProductPage")),
                    isPublic: false,
                    visible: false,
                }]
            },*/
            {
                id: "cmd-registries-tables",
                title: "Tabelle",
                icon: "Table",
                routerLink: "/tables",
                component: React.lazy(() => import("../pages/settings/business-area/BusinessAreaPage")),
                visible: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                enableRoute: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                isPublic: false,
                children: [
                    {
                        id: "cmd-settings-customer-targets",
                        title: "Categoria clienti",
                        icon: "LocationCircle",
                        routerLink: "/customertargets",
                        component: React.lazy(() => import("../pages/settings/customer-targets/CustomerTargetsPage")),
                        hasIndex: true,
                        visible: true,
                        enableRoute: true,
                        isPublic: false,
                    },
                    {
                        id: "cmd-settings-customer-sector",
                        title: "Settori",
                        icon: "LocationCircle",
                        routerLink: "/customersector",
                        component: React.lazy(() => import("../pages/settings/customer-sector/CustomerSectorPage")),
                        hasIndex: true,
                        visible: true,
                        enableRoute: true,
                        isPublic: false,
                    },
                    /*{
                        id: "cmd-settings-customer-products",
                        title: "Categoria prodotti",
                        icon: "LocationCircle",
                        routerLink: "/productcategory",
                        component: React.lazy(() => import("../pages/settings/product-category/ProductCategoryPage")),
                        hasIndex: true,
                        visible: true,
                        enableRoute: true,
                        isPublic: false,
                    },*/
                    {
                        id: "cmd-settings-customer-geographicareas",
                        title: "Area geografica",
                        icon: "LocationCircle",
                        routerLink: "/geographicareas",
                        component: React.lazy(() => import("../pages/settings/geographic-areas/GeographicAreasPage")),
                        hasIndex: true,
                        visible: true,
                        enableRoute: true,
                        isPublic: false,
                    },
                    {
                        id: "cmd-settings-ateco",
                        title: "Ateco",
                        icon: "LocationCircle",
                        routerLink: "/ateco",
                        component: React.lazy(() => import("../pages/settings/ateco/AtecoCrud")),
                        hasIndex: true,
                        visible: true,
                        enableRoute: true,
                        isPublic: false,
                    },
                    {
                        id: "cmd-settings-unitofmeasure",
                        title: "Unità di misura",
                        icon: "LocationCircle",
                        routerLink: "/unit-of-measure",
                        component: React.lazy(() => import("../pages/settings/unit-of-measure/UnitOfMeasurePage")),
                        hasIndex: true,
                        visible: true,
                        enableRoute: true,
                        isPublic: false,
                    }
                ]
            }
        ]
    },
    {
        id: "cmd-deal",
        title: "Trattative",
        routerLink: "/deal",
        component: React.lazy(() => import("../pages/main/deal/ListPage")),
        hasIndex: true,
        visible: true,
        enableRoute: true,
        isPublic: false,
        children:[{
            id: "cmd-deal-detail",
            title: "Detail",
            routerLink: "/:id",
            component: React.lazy(() => import("../pages/main/deal/DetailPage")),
            isPublic: false,
            visible: false,
        }]
    },
    {
        id: "cmd-dossier",
        title: "Fascicoli",
        routerLink: "/project",
        component: React.lazy(() => import("../pages/main/dossiers/DossiersListPage")),
        hasIndex: true,
        visible: true,
        enableRoute: true,
        isPublic: false,
        children:[{
            id: "cmd-dossier-detail",
            title: "Detail",
            routerLink: "/:id",
            component: React.lazy(() => import("../pages/main/dossiers/DossierDetailPage")),
            isPublic: false,
            visible: false
        }]
    },
    {
        id: "h3-administration",
        title: "Amministrazione",
        routerLink: "/administration",
        hasIndex: false,
        visible: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
        enableRoute: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
        isPublic: false,
        component: React.lazy(() => import("../pages/analytics/deal/ReportDealPage")),
        children: [
            {
                id: "cmd-administration-cashflow",
                title: "Cashflow",
                icon: "Diagnostic",
                routerLink: "/cashflow",
                component: React.lazy(() => import("../pages/administration/cashflow/ListPage")),
                hasIndex: true,
                visible: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                enableRoute: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                isPublic: false,
            },
            {
                id: "cmd-administration-payfee",
                title: "Commissioni",
                icon: "CalculatorPercentage",
                routerLink: "/commission",
                component: React.lazy(() => import("../pages/administration/commissions/ListPage")),
                hasIndex: true,
                visible: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                enableRoute: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                isPublic: false,
            },
            {
                id: "cmd-administration-users-mng",
                title: "Utenti",
                icon: "Group",
                routerLink: "/user",
                component: React.lazy(() => import("../pages/administration/user/ListPage")),
                hasIndex: true,
                visible: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                enableRoute: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                isPublic: false,
            },
            {
                id: "cmd-administration-team-mng",
                title: "Team",
                icon: "WebAppBuilderFragment",
                routerLink: "/team",
                component: React.lazy(() => import("../pages/administration/user/ListPage")),
                hasIndex: true,
                visible: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                enableRoute: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                isPublic: false,
                children: [
                    {
                        id: "cmd-administration-team-mng-list",
                        title: "Collaboratori",
                        icon: "LocationCircle",
                        routerLink: "/list",
                        component: React.lazy(() => import("../pages/administration/team/TeamListPage")),
                        visible: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                        enableRoute: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                        hasIndex: true,
                        isPublic: false,
                    },
                    {
                        id: "cmd-administration-team-role-mng",
                        title: "Ruoli del team",
                        icon: "LocationCircle",
                        routerLink: "/roles",
                        component: React.lazy(() => import("../pages/administration/team/TeamRolePage")),
                        visible: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                        enableRoute: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                        hasIndex: true,
                        isPublic: false,
                    }
                ]
            }
        ]
    },
]

const menuSalesChannel: RcApplicationMenu[] = [
    /*{
        id: "salesChannel",
        title: "Canali di vendita",
        icon: "Shop",
        routerLink: "/saleschannel",
        hasIndex: true,
        visible: true,
        enableRoute: true,
        isPublic: false,
        component: React.lazy(() => import("../pages/sales-channel/SalesChannelList")),
    },*/
]

const sidebarSettings: RcApplicationMenu[] = [
    {
        id: 'h4',
        title: 'Impostazioni',
        routerLink: '',
        isHeader: true,
        isPublic: true
    },
    {
        id: "cmd-settings",
        title: "Dati generali",
        icon: "Settings",
        routerLink: "/settings/data",
        component: React.lazy(() => import("../pages/settings/business-area/BusinessAreaPage")),
        visible: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
        enableRoute: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
        isPublic: false,
        children: [
            {
                id: "cmd-settings-areas",
                title: "Aree",
                icon: "LocationCircle",
                routerLink: "/businessarea",
                component: React.lazy(() => import("../pages/settings/business-area/BusinessAreaPage")),
                hasIndex: true,
                visible: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                enableRoute: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                isPublic: false,
            },
            {
                id: "cmd-settings-customer-targets",
                title: "Categoria clienti",
                icon: "LocationCircle",
                routerLink: "/customertargets",
                component: React.lazy(() => import("../pages/settings/customer-targets/CustomerTargetsPage")),
                hasIndex: true,
                visible: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                enableRoute: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                isPublic: false,
            },
            {
                id: "cmd-settings-customer-sector",
                title: "Settori",
                icon: "LocationCircle",
                routerLink: "/customersector",
                component: React.lazy(() => import("../pages/settings/customer-sector/CustomerSectorPage")),
                hasIndex: true,
                visible: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                enableRoute: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                isPublic: false,
            },
            /*{
                id: "cmd-settings-customer-products",
                title: "Categoria prodotti",
                icon: "LocationCircle",
                routerLink: "/productcategory",
                component: React.lazy(() => import("../pages/settings/product-category/ProductCategoryPage")),
                hasIndex: true,
                visible: true,
                enableRoute: true,
                isPublic: false,
            },*/
            {
                id: "cmd-settings-customer-geographicareas",
                title: "Area geografica",
                icon: "LocationCircle",
                routerLink: "/geographicareas",
                component: React.lazy(() => import("../pages/settings/geographic-areas/GeographicAreasPage")),
                hasIndex: true,
                visible: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                enableRoute: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                isPublic: false,
            },
            {
                id: "cmd-settings-cashflowcausal",
                title: "Causali cashflow",
                icon: "LocationCircle",
                routerLink: "/cashflowcausal",
                component: React.lazy(() => import("../pages/settings/cashflow-causal/CashFlowCausalCrud")),
                hasIndex: true,
                visible: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                enableRoute: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                isPublic: false,
            },
            {
                id: "cmd-settings-commissiontype",
                title: "Tipi commissioni",
                icon: "LocationCircle",
                routerLink: "/commissiontype",
                component: React.lazy(() => import("../pages/settings/commission-type/CommissionTypeCrud")),
                hasIndex: true,
                visible: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                enableRoute: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                isPublic: false,
            },
            {
                id: "cmd-settings-pipeline",
                title: "Pipelines",
                icon: "LocationCircle",
                routerLink: "/pipeline",
                component: React.lazy(() => import("../pages/settings/pipeline/PipelineListPage")),
                hasIndex: true,
                visible: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                enableRoute: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                isPublic: false,
                children:[{
                    id: "cmd-settings-pipeline-detail",
                    title: "Pipeline Stages",
                    routerLink: "/:id",
                    component: React.lazy(() => import("../pages/settings/pipeline/PipelineStageListPage")),
                    isPublic: false,
                    visible: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                }]
            },
            {
                id: "cmd-settings-ateco",
                title: "Ateco",
                icon: "LocationCircle",
                routerLink: "/ateco",
                component: React.lazy(() => import("../pages/settings/ateco/AtecoCrud")),
                hasIndex: true,
                visible: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                enableRoute: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                isPublic: false,
            },
            {
                id: "cmd-settings-unitofmeasure",
                title: "Unità di misura",
                icon: "LocationCircle",
                routerLink: "/unit-of-measure",
                component: React.lazy(() => import("../pages/settings/unit-of-measure/UnitOfMeasurePage")),
                hasIndex: true,
                visible: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                enableRoute: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                isPublic: false,
            },
            {
                id: "cmd-settings-import",
                title: "Import dati",
                icon: "ImportAllMirrored",
                routerLink: "/settings/import",
                component: React.lazy(() => import("../pages/settings/import/ImportPage")),
                visible: user => enabledForRole(user, ['administrator']),
                enableRoute: user => enabledForRole(user, ['administrator']),
                isPublic: false,
                hasIndex: true,
            },
            /*{
                id: "cmd-settings-customField",
                title: "Attributi personalizzati",
                icon: "PageHeaderEdit",
                routerLink: "/settings/customField",
                component: React.lazy(() => import("../pages/settings/custom-field/CustomField")),
                visible: user => enabledForRole(user, ['administrator']),
                enableRoute: user => enabledForRole(user, ['administrator']),
                isPublic: false,
                hasIndex: true,
                children: [
                    {
                        id: "cmd-settings-customField-list",
                        title: "Lista",
                        icon: "ReportDocument",
                        routerLink: "/list",
                        component: React.lazy(() => import("../pages/settings/custom-field/CustomField")),
                        hasIndex: true,
                        visible: user => enabledForRole(user, ['administrator']),
                        enableRoute: user => enabledForRole(user, ['administrator']),
                        isPublic: false,
                    },
                    {
                        id: "cmd-settings-customField-set",
                        title: "Set",
                        icon: "ReportDocument",
                        routerLink: "/set",
                        component: React.lazy(() => import("../pages/settings/custom-field/CustomFieldSet")),
                        hasIndex: true,
                        visible: user => enabledForRole(user, ['administrator']),
                        enableRoute: user => enabledForRole(user, ['administrator']),
                        isPublic: false,
                    }
                ]
            },*/
        ]
    }
]

const sidebarReport: RcApplicationMenu[] = [
    {
        id: "cmd-reports",
        title: "Reports",
        routerLink: "/report",
        component: React.lazy(() => import("../pages/analytics/deal/ReportDealPage")),
        hasIndex: true,
        visible: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
        enableRoute: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
        isPublic: false,
        children: [
            {
                id: "cmd-reports-deal",
                title: "Commerciale",
                routerLink: "/commercial",
                icon: "ReportDocument",
                component: React.lazy(() => import("../pages/analytics/deal/ReportDealPage")),
                hasIndex: false,
                visible: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                enableRoute: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                isPublic: false,
                children: [
                    {
                        id: "cmd-reports-deal-general",
                        title: "Analisi generale",
                        icon: "ReportDocument",
                        routerLink: "/",
                        component: React.lazy(() => import("../pages/analytics/deal/ReportDealPage")),
                        hasIndex: true,
                        visible: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                        enableRoute: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                        isPublic: false,
                    },
                    {
                        id: "cmd-reports-deal-current-year",
                        title: `Comparazione`,
                        icon: "ReportDocument",
                        routerLink: "/current-year",
                        component: React.lazy(() => import("../pages/analytics/deal/ReportDealCurrentYearPage")),
                        hasIndex: true,
                        visible: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                        enableRoute: user => enabledForRole(user, ['administrator', 'manager', 'administrative']),
                        isPublic: false,
                    }
                ]
            }
        ]
    },
]

export const sidebar: RcApplicationMenu[] = [
    ...siderbarMain,
    ...sidebarReport,
    ...menuSalesChannel,
    ...sidebarSettings
]
