import {IRcTheme} from "@rocketcodeit/react-components";

export let rcTheme: IRcTheme = {
    fluentUi: {
        palette: {
            //themePrimary: '#45199d',
            themePrimary: '#1b4b88',
            themeDark: '#324c4d',
        },
    },
    rc: {
        logo: {
            path: "/assets/staffnetwork-cloud.png",
            //path: "/assets/fluxo-logo-white.png",
            // text: "Staff Network",
            color: "#000",
        },
        navigationUI: {
          position: 'header'
        },
        authRocket: {
            logo: {
                //path: "/assets/rocket-logo-dark.png"
                path: "/assets/staffnetwork-cloud-dark.png"
            },
        },
        headerBar: {
            //backgroundColor: '#45199d',
            backgroundColor: "#1b4b88",
        }
    }
};
